import React from 'react';
import {
    BooleanField,
    BooleanInput,
    Create,
    Datagrid,
    Edit,
    EditButton,
    List,
    maxLength,
    NumberInput,
    SelectInput,
    SimpleForm,
    TextField,
    TextInput,
    required,
    regex,
    minValue,
    number,
    choices, useRecordContext,
} from 'react-admin';
import {FormControl, FormHelperText, Grid, Typography} from "@material-ui/core";

// Validation configuration
const validateLinkText = [maxLength(30, "Bitte maximal 30 Zeichen eingeben"), required("Dieses Feld ist ein Pflichtfeld")]
const validateLinkTarget = [regex(/^\/([a-z0-9]+\/)?[^\/.]+$/, "Falsches Linkformat. Nur interne Links mit führendem Slash (Korrekt: /wettbewerb)"), required("Dieses Feld ist ein Pflichtfeld")]
const validateOrder = [minValue(0), number(0), required("Dieses Feld ist ein Pflichtfeld")]
const validatePosition = [choices(['primary', 'secondary']), required("Dieses Feld ist ein Pflichtfeld")]

const CustomPositionField = ({ source, record = useRecordContext() }) => {
    if(!record) return null;
    let menuDescription;
    record[source] === 'primary' ? menuDescription = 'Hauptmenü' : menuDescription = 'Fusszeilenmenü'
    return <Typography>{menuDescription}</Typography>
}
export const MenusList = (props) => (
    <List {...props}>
        <Datagrid>
            <CustomPositionField label="Position" source="position"/>
            <TextField label="Sortierung" source="order"/>
            <TextField label="Link-Text" source="text"/>
            <BooleanField label="Hervorhebung?" source="highlight"/>
            <EditButton/>
        </Datagrid>
    </List>
);

export const MenusEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <SelectInput
                        fullWidth
                        label="Position"
                        source="position"
                        validate={validatePosition}
                         choices={[
                            { id: 'primary', name: 'Hauptmenü' },
                            { id: 'secondary', name: 'Fusszeilenmenü' },
                        ]} />
                </Grid>
                <Grid item xs={6}>
                    <NumberInput fullWidth label="Sortierung" source="order" validate={validateOrder}/>
                </Grid>
                <Grid item xs={6}>
                    <TextInput fullWidth label="Link-Text" source="text" validate={validateLinkText}/>
                </Grid>
                <Grid item xs={6}>
                    <TextInput fullWidth label="Link-Ziel" source="linkTarget" validate={validateLinkTarget}/>
                </Grid>
                <Grid item xs={12}>
                    <FormControl>
                        <BooleanInput fullWidth label="Hervorhebung?" source="highlight" />
                        <FormHelperText>Macht aus dem Link einen Button</FormHelperText>
                    </FormControl>
                </Grid>
            </Grid>
        </SimpleForm>
    </Edit>
);

export const MenusCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <SelectInput
                        fullWidth
                        label="Position"
                        source="position"
                        validate={validatePosition}
                        choices={[
                            { id: 'primary', name: 'Hauptmenü' },
                            { id: 'secondary', name: 'Fusszeilenmenü' },
                        ]} />
                </Grid>
                <Grid item xs={6}>
                    <NumberInput fullWidth label="Sortierung" source="order" validate={validateOrder}/>
                </Grid>
                <Grid item xs={6}>
                    <TextInput fullWidth label="Link-Text" source="text" validate={validateLinkText}/>
                </Grid>
                <Grid item xs={6}>
                    <TextInput fullWidth label="Link-Ziel" source="linkTarget" validate={validateLinkTarget}/>
                </Grid>
                <Grid item xs={12}>
                    <FormControl>
                        <BooleanInput fullWidth label="Hervorhebung?" source="highlight"/>
                        <FormHelperText>Macht aus dem Link einen Button</FormHelperText>
                    </FormControl>
                </Grid>
            </Grid>
        </SimpleForm>
    </Create>
);
