import React, {useState} from 'react';
import {Layout, MenuItemLink} from 'react-admin';
import SettingsIcon from '@material-ui/icons/SettingsApplications';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ProjectIcon from '@material-ui/icons/AssignmentInd';
import PageIcon from '@material-ui/icons/Description';
import CTAIcon from '@material-ui/icons/Announcement';
import UsersIcon from '@material-ui/icons/People';
import MenuItemsIcon from '@material-ui/icons/List';
import GearIcon from '@material-ui/icons/Settings';
import SubMenu from './SubMenu'
import {ExpandLess} from "@material-ui/icons";

const menuItems = [
    {name: 'config/phases', text: 'Wettbewerb', icon: <SettingsIcon/>},
    {name: 'cta', text: 'Calls-To-Action', icon: <CTAIcon/>},
    {name: 'menus', text: 'Navigation', icon: <MenuItemsIcon/>},
    {name: 'admin/projects', text: 'Projekte', icon: <ProjectIcon/>},
    {name: 'users', text: 'Nutzer', icon: <UsersIcon/>},
    {name: 'config/funding-parameters', text: 'Förderungseinstellungen', icon: <PageIcon/>}, // @Todo: select a better icon
    {name: 'pages', text: 'Seiten', icon: <PageIcon/>}
];

//let menuComponentKitOpen = false;

const CustomMenu = ({onMenuClick, open, logout}) => {

    const [menuComponentKitOpen, setMenuComponentKitOpen] = useState(false)

    const handleToggle = () => {
        setMenuComponentKitOpen(!menuComponentKitOpen);
        console.log(menuComponentKitOpen)
    }

    return (
        <>
            <MenuItemLink
                key='dashboard'
                to={'/dashboard'}
                primaryText='Übersicht'
                leftIcon={<DashboardIcon/>}
                onClick={onMenuClick}
            />
            <SubMenu
                handleToggle={() => handleToggle('menuComponentKitOpen')}
                isOpen={menuComponentKitOpen}
                sidebarIsOpen={open}
                name="Vorlagen"
                icon={<ExpandLess/>}>

                <MenuItemLink
                    key='vorlagen/socialsharebuttons'
                    to={`/vorlagen/socialsharebuttons`}
                    primaryText='Social Share Buttons'
                    leftIcon={<SettingsIcon/>}
                    onClick={onMenuClick}/>
                <MenuItemLink
                    key='vorlagen/filedownload'
                    to={`/vorlagen/filedownload`}
                    primaryText='Datei-Download'
                    leftIcon={<SettingsIcon/>}
                    onClick={onMenuClick}/>
            </SubMenu>
            {menuItems.map(item => (
                <MenuItemLink
                    key={item.name}
                    to={`/${item.name}`}
                    primaryText={item.text}
                    leftIcon={item.icon}
                    onClick={onMenuClick}
                />
            ))}
        </>
    )
};

const CustomLayout = props => <Layout {...props} menu={CustomMenu}/>;

export default CustomLayout;
