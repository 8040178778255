import React from 'react';

const CKSocialShareButtons = ({staticContext}) => {
    const iframe = {
        width: '100%',
        height: '1500px',
        border: 0
    }

    return (
        <iframe style={iframe} src="https://storybook.socialvalue.de/?path=/story/asset-downloads--download-instagram&singleStory=true"/>
    );
};

export default CKSocialShareButtons;
