import React from 'react';
import {fetchUtils, Admin, Resource, CustomRoutes, useStore} from 'react-admin';
import { Route } from 'react-router-dom';
import { PageList, PageEdit, PageCreate } from './components/Pages/Pages';
import {
    FundingParameterList,
    FundingParameterEdit,
    FundingParameterCreate
} from './components/FundingParameters/FundingParameters'; // @Todo: Think about nomenclature once more
import { CTAList, CTAEdit, CTACreate } from './components/CallsToAction/CTA';
import { ProjectList, ProjectEdit } from './components/Projects/Projects';
import { UserEdit, UserList, UserCreate, UserShow } from "./components/Users/Users";
import { MenusList, MenusEdit, MenusCreate } from "./components/Menus/Menus";
import { createTheme } from '@material-ui/core/styles';
import ProjectIcon from '@material-ui/icons/AssignmentInd';
import simpleRestProvider from './utilities/dataProvider';
import Dashboard from './components/Dashboard/Dashboard';
import PageIcon from '@material-ui/icons/Description';
import MenuItemsIcon from '@material-ui/icons/List';
import authProvider from "./utilities/authProvider";
import CustomLayout from "./components/UI/Menu/Menu";
import { PhasesEdit } from './components/Phases/Phases';
import CKSocialShareButtons from './components/ComponentKit/SocialShareButtons';
import CKFileDownload from './components/ComponentKit/FileDownload';

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const token = localStorage.getItem('idToken');
    options.headers.set('Authorization', `Bearer ${token}`);
    return fetchUtils.fetchJson(url, options);
};

const campaignApiUrl = localStorage.getItem('RaStore.campaign.apiUrl');
console.log(campaignApiUrl)
//const dataProvider = simpleRestProvider(localStorage.getItem(campaignApiUrl), httpClient);
const dataProvider = simpleRestProvider(process.env.REACT_APP_API_URL, httpClient);
const theme = createTheme({
    palette: {
        primary: {
            main: '#74c91e'
        },
        secondary: {
            main: '#aaff54'
        }
    },
    typography: {
        body2: {
            fontSize: '1rem'
        }
    },
});

const App = () => {

    return (
        <Admin
            layout={CustomLayout}
            theme={theme}
            dashboard={Dashboard}
            disableTelemetry
            requireAuth
            authProvider={authProvider}
            dataProvider={dataProvider}>

            <CustomRoutes>
                <Route path="/dashboard" element={<Dashboard/>}/>
                <Route path="/config/phases" element={<PhasesEdit/>} />
                <Route path="/vorlagen/socialsharebuttons" element={<CKSocialShareButtons/>} />
                <Route path="/vorlagen/filedownload" element={<CKFileDownload/>} />
            </CustomRoutes>
            <Resource options={{label: "CTA"}} name="cta" list={CTAList} edit={CTAEdit} create={CTACreate}
                      icon={PageIcon}/>
            <Resource options={{label: "Navigation"}} name="menus" list={MenusList} edit={MenusEdit}
                      create={MenusCreate} icon={MenuItemsIcon}/>
            <Resource
                options={{label: "Förderungseinstellungen"}}
                name="config/funding-parameters"
                list={FundingParameterList}
                icon={PageIcon}
                edit={FundingParameterEdit}
                create={FundingParameterCreate}
            />
            <Resource options={{label: "Projekte"}} name="admin/projects" list={ProjectList} edit={ProjectEdit}
                      icon={ProjectIcon}/>
            <Resource options={{label: "Seiten"}} name="pages" list={PageList} edit={PageEdit} create={PageCreate}
                      icon={PageIcon}/>
            <Resource options={{label: "Nutzer"}} name="users" list={UserList} edit={UserEdit} create={UserCreate}
                      show={UserShow} icon={ProjectIcon}/>
        </Admin>
    );
}

export default App;
