import * as React from 'react';
import {
    List,
    Datagrid,
    TextField,
    BooleanField,
    useRecordContext,
    Edit,
    SimpleForm,
    TextInput, SelectInput, FormDataConsumer, BooleanInput, maxLength, regex, Create
} from 'react-admin';
import {Grid} from "@mui/material";

// Validation configuration
const validateCTATitle = [maxLength(50, "Bitte maximal 50 Zeichen eingeben")]
const validateCTAButton = [maxLength(30, "Bitte maximal 30 Zeichen eingeben")]
const validateCTACopy = [maxLength(420, "Bitte maximal 420 Zeichen eingeben")]
const validateLinkTarget = [regex(/^\/([a-z0-9]+\/)?[^\/.]+$/, "Falsches Linkformat. Nur interne Links mit führendem Slash (Korrekt: /wettbewerb)")]

// Linked select boxes configuration
const pages = ['home', 'projekte', 'bewerbung'];
const positions = {
    home: [1, 2, 3],
    projekte: [1],
    bewerbung: [1],
};
const toChoices = items => items.map(item => ({ id: item, name: item }));

export const CTAEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextInput disabled fullWidth label="ID" source="_id"/>
                </Grid>
                <Grid item xs={6}>
                    <SelectInput fullWidth label="Seite" source="page" choices={toChoices(pages)} />
                </Grid>
                <Grid item xs={6}>
                    <FormDataConsumer>
                        {({ formData, ...rest }) => (
                            <SelectInput
                                fullWidth
                                source="position"
                                choices={
                                    formData.page
                                        ? toChoices(positions[formData.page])
                                        : []
                                }
                                {...rest}
                            />
                        )}
                    </FormDataConsumer>
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextInput fullWidth label="Button-Text" source="buttonText" validate={validateCTAButton}/>
                </Grid>
                <Grid item xs={12} md={4}>
                    <SelectInput style={{margin: 0}} fullWidth label="Button-Farbe" source="buttonColor" choices={[
                        { id: 'primary', name: 'Primärfarbe' },
                        { id: 'secondary', name: 'Sekundärfarbe' },
                    ]}/>
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextInput fullWidth label="Button-Link-URL" source="linkUrl" validate={validateLinkTarget} />
                </Grid>
                <Grid item xs={12}>
                    <TextInput fullWidth label="Überschrift" source="headline" validate={validateCTATitle}/>
                </Grid>
                <Grid item xs={12}>
                    <TextInput multiline label="Fließtext" source="copy" validate={validateCTACopy} fullWidth/>
                </Grid>
                <Grid item xs={12} md={4}>
                    <BooleanInput label="Weißer CTA-Text?" source="textWhite" />
                </Grid>
                <Grid item xs={12} md={4}>
                    <BooleanInput label="Kontur-Button?" source="outline" />
                </Grid>
                <Grid item xs={12} md={4}>
                    <BooleanInput label="Button sichtbar?" source="buttonVisibility" />
                </Grid>
            </Grid>
        </SimpleForm>
    </Edit>
);

export const CTAList = () => (
    <List>
        <Datagrid expand={<CTAEdit/>}>
            <TextField label="Seite" source="page"/>
            <TextField label="Position" source="position"/>
            <TextField label="Button-Text" source="buttonText"/>
            <TextField label="Button-Farbe" source="buttonColor"/>
            <TextField label="Link-URL" source="linkUrl"/>
            <TextField label="Überschrift" source="headline"/>
            <TextField label="Fließtext" source="copy"/>
            <BooleanField label="Button-Kontur?" source="outline"/>
            <BooleanField label="Weißer Text?" source="textWhite"/>
            <BooleanField label="Button sichtbar?" source="buttonVisibility"/>
        </Datagrid>
    </List>
);

export const CTACreate = props => (
    <Create {...props}>
        <SimpleForm redirect={false}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextInput disabled fullWidth label="ID" source="_id"/>
                </Grid>
                <Grid item xs={6}>
                    <SelectInput fullWidth label="Seite" source="page" choices={toChoices(pages)} />
                </Grid>
                <Grid item xs={6}>
                    <FormDataConsumer>
                        {({ formData, ...rest }) => (
                            <SelectInput
                                fullWidth
                                source="position"
                                choices={
                                    formData.page
                                        ? toChoices(positions[formData.page])
                                        : []
                                }
                                {...rest}
                            />
                        )}
                    </FormDataConsumer>
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextInput fullWidth label="Button-Text" source="buttonText" validate={validateCTAButton}/>
                </Grid>
                <Grid item xs={12} md={4}>
                    <SelectInput style={{margin: 0}} fullWidth label="Button-Farbe" source="buttonColor" choices={[
                        { id: 'primary', name: 'Primärfarbe' },
                        { id: 'secondary', name: 'Sekundärfarbe' },
                    ]}/>
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextInput fullWidth label="Button-Link-URL" source="linkUrl"/>
                </Grid>
                <Grid item xs={12}>
                    <TextInput fullWidth label="Überschrift" source="headline" validate={validateCTATitle}/>
                </Grid>
                <Grid item xs={12}>
                    <TextInput fullWidth multiline label="Fließtext" source="copy" validate={validateCTACopy}/>
                </Grid>
                <Grid item xs={12} md={4}>
                    <BooleanInput label="Outline-Button?" source="outline" />
                </Grid>
                <Grid item xs={12} md={4}>
                    <BooleanInput label="Weißer CTA-Text?" source="textWhite" />
                </Grid>
                <Grid item xs={12} md={4}>
                    <BooleanInput label="Button-Sichtbarkeit" source="buttonVisibility" defaultValue/>
                </Grid>
            </Grid>
        </SimpleForm>
    </Create>
);
